@import './../../../sass/mixins';
@import './../../../sass/variables';

[data-is="special-banner"] {
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding-top: calc(120 / 990 * 100%);
  background-color: $color-grey;
  transition: opacity $animation-duration-default ease-out;

  &:hover {
    opacity: 0.8;
  }

  .special-banner-wrapper {
    position: absolute;
    inset: 0;

    img {
      position: absolute;
      top: 0;
      left: 0;
      align-self: center;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

[data-is="special-banner-ads"], [data-is="special-banner-placeholder"] {
  min-height: 90px;

  @media (max-width: $size-sm) {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
  }
}
